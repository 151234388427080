<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { customRouterState } = useCustomRouter();
const page = ref(1);
const limit = ref(10);
const totalPages = ref(0);
const currentPage = ref(1);

const mockData = [
  {
    purchaseId: '67bde1458e4c95b8f62f6835',
    hashRate: 1000,
    hashPower: 3000,
    purchaseDate: '1/1/25',
    userId: 1145255607,
    hashPowerDeduction: 800,
    hashPowerBalance: 2200,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    purchaseId: '67bfa3f9a59ca843ba7c2dc2',
    hashRate: 1000,
    hashPower: 3000,
    purchaseDate: '1/1/25',
    userId: 5727613697,
    hashPowerDeduction: 800,
    hashPowerBalance: 2992,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    purchaseId: '67bfa4a1a59ca843ba7c2dc7',
    hashRate: 1000,
    hashPower: 3000,
    purchaseDate: '1/1/25',
    userId: 7122182283,
    hashPowerDeduction: 800,
    hashPowerBalance: 2992,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    purchaseId: '67bfa435a59ca843ba7c2dc5',
    hashRate: 2000,
    hashPower: 6000,
    purchaseDate: '1/1/25',
    userId: 530596065,
    hashPowerDeduction: 1600,
    hashPowerBalance: 5984,
    pennyUsd: 1120,
    pennyQty: 1120,
    stoneUsd: 400,
    stoneQty: 40,
    fee: 80,
  },
  {
    purchaseId: '67bfa579a59ca843ba7c2dcc',
    hashRate: 1000,
    hashPower: 3000,
    purchaseDate: '1/1/25',
    userId: 537670647,
    hashPowerDeduction: 800,
    hashPowerBalance: 2992,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    purchaseId: '67bfa579a59ca843ba7c2dcc',
    hashRate: 1000,
    hashPower: 3000,
    purchaseDate: '1/1/25',
    userId: 530596065,
    hashPowerDeduction: 800,
    hashPowerBalance: 2992,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    purchaseId: '67bde1458e4c95b8f62f6835',
    hashRate: 1000,
    hashPower: 3000,
    purchaseDate: '1/1/25',
    userId: 5727613697,
    hashPowerDeduction: 800,
    hashPowerBalance: 2200,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    purchaseId: '67bfa579a59ca843ba7c2dcc',
    hashRate: 1000,
    hashPower: 3000,
    purchaseDate: '1/1/25',
    userId: 5727613697,
    hashPowerDeduction: 800,
    hashPowerBalance: 2992,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
];

const newMockData = [
  {
    userId: 1145255607,
    hashRate: 1000,
    hashPower: 3000,
    hashPowerDeduction: 800,
    hashPowerBalance: 2200,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    userId: 5727613697,
    hashRate: 3000,
    hashPower: 9000,
    hashPowerDeduction: 2400,
    hashPowerBalance: 8184,
    pennyUsd: 1680,
    pennyQty: 1680,
    stoneUsd: 600,
    stoneQty: 60,
    fee: 120,
  },
  {
    userId: 7122182283,
    hashRate: 1000,
    hashPower: 3000,
    hashPowerDeduction: 800,
    hashPowerBalance: 2992,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
  {
    userId: 530596065,
    hashRate: 3000,
    hashPower: 9000,
    hashPowerDeduction: 2400,
    hashPowerBalance: 8976,
    pennyUsd: 1680,
    pennyQty: 1680,
    stoneUsd: 600,
    stoneQty: 60,
    fee: 120,
  },
  {
    userId: 537670647,
    hashRate: 1000,
    hashPower: 3000,
    hashPowerDeduction: 800,
    hashPowerBalance: 2992,
    pennyUsd: 560,
    pennyQty: 560,
    stoneUsd: 200,
    stoneQty: 20,
    fee: 40,
  },
];

const navigateTo = async (page: string) => {
  if (page === 'bonusentitlement') {
    await router.push('/pennywhalestma/admin/salesreport/bonusentitle');
    window.location.reload();
  } else if (page === 'salesreport') {
    await router.replace('/pennywhalestma/admin/salesreport/reportview');
    window.location.reload();
  } else if (page === 'bonusdistribution') {
    await router.replace('/pennywhalestma/admin/salesreport/bonusdist');
    window.location.reload();
  }
};

const formattedDate = (purchaseDate: string) => {
  const date = new Date(purchaseDate);
  const formatDate = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear().toString().slice(-2)},${date.getHours()}:${date.getMinutes()}`;
  return formatDate;
};
onMounted(async () => {});
</script>

<template>
  <div class="bg-white h-screen lg:w-11/12 px-4 py-4 text-base mx-auto">
    <!-- Header section -->
    <div class="text-center mx-auto font-bold pb-4">
      <div class="text-center mx-auto text-2xl font-bold pb-4">
        <p>Hash Mining Report</p>
      </div>
      <div class="flex justify-center space-x-2 pb-10">
        <button
          class="bg-white text-black py-2 px-4 rounded border border-gray-300"
          @click="navigateTo('salesreport')"
        >
          Purchase List
        </button>

        <button
          class="text-black py-2 px-4 rounded border border-gray-300"
          :class="[customRouterState === 'bonusentitlement' ? ' bg-neutral-300' : 'bg-white']"
          @click="navigateTo('bonusentitlement')"
        >
          Bonus Entitlement
        </button>

        <button
          class="text-black py-2 px-4 rounded border border-gray-300"
          :class="[customRouterState === 'passiveminingreport' ? ' bg-neutral-300' : 'bg-white']"
          @click="navigateTo('passiveminingreport')"
        >
          Passive Mining
        </button>

        <button
          class="bg-white text-black py-2 px-4 rounded border border-gray-300"
          @click="navigateTo('bonusdistribution')"
        >
          Bonus Distribution
        </button>
      </div>
    </div>
    <!-- Passive Mining Section -->
    <p class="text-lg font-bold pb-2">Passive Mining Detail</p>
    <div class="py-4">
      <p>Date: {{ new Date().toLocaleDateString() }}</p>
      <p>Rate : 0.01</p>
    </div>
    <div class="overflow-x-auto">
      <table
        class="table w-full border-collapse border border-gray-300 text-center text-sm mx-auto"
      >
        <thead class="bg-gray-100">
          <tr>
            <th>Purchase ID</th>
            <th>Hash Rate</th>
            <th>Hash Power</th>
            <th>Purchase Date</th>
            <th>User ID</th>
            <th>Hash Power Deduction</th>
            <th>Hash Power Balance</th>
            <th>PENNY(USD)</th>
            <th>PENNY(Qty)</th>
            <th>Stone(USD)</th>
            <th>Stone(Qty)</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(item, index) in mockData" class="bg-white border border-gray-300">
            <td class="border-b border-gray-500">{{ item.purchaseId }}</td>
            <td class="border-b border-gray-500">{{ item.hashRate }}</td>
            <td class="border-b border-gray-500">{{ item.hashPower }}</td>
            <td class="border-b border-gray-500">{{ item.purchaseDate }}</td>
            <td class="border-b border-gray-500">{{ item.userId }}</td>
            <td class="border-b border-gray-500">{{ item.hashPowerDeduction }}</td>
            <td class="border-b border-gray-500">{{ item.hashPowerBalance }}</td>
            <td class="border-b border-gray-500">{{ item.pennyUsd }}</td>
            <td class="border-b border-gray-500">{{ item.pennyQty }}</td>
            <td class="border-b border-gray-500">{{ item.stoneUsd }}</td>
            <td class="border-b border-gray-500">{{ item.stoneQty }}</td>
            <td class="border-b border-gray-500">{{ item.fee }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- Passive Mining Summary Section -->
    <p class="text-lg font-bold py-4">Passive Mining</p>

    <div class="overflow-x-auto">
      <table
        class="table w-full border-collapse border border-gray-300 text-center text-sm mx-auto"
      >
        <thead class="bg-gray-100">
          <tr>
            <th>User ID</th>
            <th>Hash Rate</th>
            <th>Hash Power</th>
            <th>Hash Power Deduction</th>
            <th>Hash Power Balance</th>
            <th>PENNY(USD)</th>
            <th>PENNY(Qty)</th>
            <th>Stone(USD)</th>
            <th>Stone(Qty)</th>
            <th>Fee</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="(item, index) in newMockData" class="bg-white border border-gray-300">
            <td class="border-b border-gray-500">{{ item.userId }}</td>
            <td class="border-b border-gray-500">{{ item.hashRate }}</td>
            <td class="border-b border-gray-500">{{ item.hashPower }}</td>
            <td class="border-b border-gray-500">{{ item.hashPowerDeduction }}</td>
            <td class="border-b border-gray-500">{{ item.hashPowerBalance }}</td>
            <td class="border-b border-gray-500">{{ item.pennyUsd }}</td>
            <td class="border-b border-gray-500">{{ item.pennyQty }}</td>
            <td class="border-b border-gray-500">{{ item.stoneUsd }}</td>
            <td class="border-b border-gray-500">{{ item.stoneQty }}</td>
            <td class="border-b border-gray-500">{{ item.fee }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
