const useCustomRouter = () => {
  //const page = ref('treasure');
  const page = ref('pennyworld');
  const historyStack = useState<string[]>('historyStack', () => []);
  const customRouterState = useState('CustomRouterState', () => page.value);

  const route = useRoute();

  if (route.path === '/pennywhalestma/admin/withdraw/request/withdrawList') {
    customRouterState.value = 'withdrawalrequest';
  }

  if (route.path === '/pennywhalestma/admin/taskprogress/taskcheck') {
    customRouterState.value = 'taskapproval';
  }
  if (route.path === '/pennywhalestma/admin/salesreport/reportview') {
    customRouterState.value = 'salesreport';
  }
  if (route.path === '/pennywhalestma/admin/salesreport/bonusentitle') {
    customRouterState.value = 'bonusentitlement';
  }
  if (route.path === '/pennywhalestma/admin/salesreport/bonusdist') {
    customRouterState.value = 'bonusdistribution';
  }
  if (route.path === '/pennywhalestma/admin/salesreport/passive-mining') {
    customRouterState.value = 'passiveminingreport';
  }
  const navigateToPage = (
    page:
      | 'treasure'
      | 'friend'
      | 'assetvault'
      | 'taskpage'
      | 'upgradepage'
      | 'booster'
      | 'monopoly'
      | 'leaderboard'
      | 'campaignrule'
      | 'guessbitcoin'
      | 'guessleaderboard'
      | 'redotpay'
      | 'mainguessbtc'
      | 'shellpackage'
      | 'guesscampaignrule'
      | 'pennyworld'
      | 'flipcardgame'
      | 'hashminingisland'
      | 'buyhash'
      | 'arcadeisland'
      | 'arcaderoom'
      | 'communityisland'
      | 'pioneerpackage'
      | 'withdraw'
      | 'salesreport'
      | 'bonusentitlement'
      | 'bonusdistribution'
      | 'packagedetail'
      | 'passiveminingreport',
  ) => {
    historyStack.value.push(customRouterState.value);
    // console.log(historyStack.value)
    customRouterState.value = page;
  };

  const goBack = () => {
    if (historyStack.value) {
      if (historyStack.value[historyStack.value.length - 1] === 'pennyworld') {
        customRouterState.value = 'pennyworld';
        historyStack.value = [];
      } else {
        customRouterState.value = historyStack.value.pop() || 'pennyworld';
      }
    } else {
      console.warn('No previous state to go back to');
    }
  };

  return {
    customRouterState,
    navigateToPage,
    goBack,
  };
};

export default useCustomRouter;
