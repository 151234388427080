<script setup lang="ts">
import { useI18n } from 'vue-i18n';
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { refreshSignIn } = authStore;
const { navigateToPage } = useCustomRouter();
const { t } = useI18n();
const { locale } = useI18n();

const isRefreshing = ref(false);
const handleRefresh = () => {
  isRefreshing.value = true;
  setTimeout(() => {
    isRefreshing.value = false;
  }, 500);
  refreshSignIn();
};

onMounted(() => {
  if (authState.value.language === 'zh-hans') {
    locale.value = authState.value.language;
  }
});
</script>

<template>
  <div class="h-screen bg-[url('/images/pennyworld/bg_purchase_rate.png')] bg-cover bg-top">
    <div class="flex flex-row justify-between px-4 pb-4 pt-6">
      <img
        src="/images/pennyworld/World.png"
        alt=""
        class="w-10 h-10"
        @click="navigateToPage('pennyworld')"
      />
      <div class="reverse flex flex-row-reverse gap-4">
        <div class="flex p-1 gap-2 rounded">
          <img src="/images/tether-usdt-logo.png" alt="" class="w-6 h-6" />
          <p class="text-white font-bold">{{ authState.usdtBalance.toFixed(6) }}</p>
        </div>
      </div>
    </div>
    <!-- page title -->
    <div class="pt-8">
      <p class="text-white text-center font-bold text-3xl pb-4 font-avenger">Package Purchased</p>
    </div>

    <div class="bg-gray-100 mx-4 rounded px-4 py-2">
      <p class="text-center pb-4">Package 1</p>
      <p>Purchase Date : 1 January 2025 , 18:34</p>
      <p>Hash Rate : 1000 Th/s</p>
      <p>Hash Balance: 3000</p>
    </div>

    <div class="bg-gray-100 mx-4 rounded px-4 py-2">
      <p class="text-center pb-4">Package 2</p>
      <p>Purchase Date : 3 January 2025 , 10:34</p>
      <p>Hash Rate : 100 Th/s</p>
      <p>Hash Balance: 300</p>
    </div>

    <div class="flex pt-28 mx-8">
      <button
        class="grow text-black bg-white hover:bg-gray-300 rounded-lg text-sm px-5 py-2.5"
        @click="navigateToPage('pioneerpackage')"
      >
        {{ t('Purchase') }}
      </button>
    </div>
    <div class="flex justify-center items-center pt-10">
      <img src="/images/pennyworld/mining_nolabel.png" alt="mining" class="w-1/2" />
    </div>
  </div>
</template>
