<script setup lang="ts">
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { navigateToPage } = useCustomRouter();

const cards = ref([
  { front: '/images/flipcard/1.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/2.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/3.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/4.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/5.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/6.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/7.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/8.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/9.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/10.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/11.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/12.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/13.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/14.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/15.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/16.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/17.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/18.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/1.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/2.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/3.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/4.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/5.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/6.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/7.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/8.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/9.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/10.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/11.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/12.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/13.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/14.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/15.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/16.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/17.png', back: '/images/flipcard/new_card_back.png' },
  { front: '/images/flipcard/18.png', back: '/images/flipcard/new_card_back.png' },
]);

const flippedCards = ref([]);

const isFlipped = (index: number) => flippedCards.value.includes(index);

const handleCardClick = (index: number) => {
  console.log('handleCardClick masuk ', index);
  flippedCards.value = [...flippedCards.value, index]; // Update flippedCards directly
  const cardElement = document.querySelector(`.shuffle:nth-child(${index + 1})`);
  if (cardElement) {
    cardElement.classList.add('zoom-flip');
    setTimeout(() => {
      cardElement.classList.remove('zoom-flip');
    }, 1000);
  }
};

// const handleCardClick = (index: number) => {
//   console.log('handleCardClick masuk ', index)
//   const cardElement = document.querySelector(`.shuffle:nth-child(${index + 1})`)
//   if (cardElement) {
//     cardElement.classList.add('zoom-flip')
//     cardElement.addEventListener('animationend', () => {
//       flippedCards.value = [...flippedCards.value, index];
//     }, { once: true });
//   }
// };

const shuffledCards = ref([]);
function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

onMounted(() => {
  shuffledCards.value = shuffleArray([...cards.value]);
});
</script>

<template>
  <div class="h-screen pb-16 bg-[url('/images/pennyworld/ocean.png')] bg-cover bg-top">
    <div class="w-full max-w-xl">
      <div class="flex flex-row justify-between px-4 pt-6">
        <img
          src="/images/pennyworld/World.png"
          alt=""
          class="w-10 h-10"
          @click="navigateToPage('pennyworld')"
        />
        <div class="reverse flex flex-row-reverse gap-4">
          <div class="flex p-1 gap-2 rounded">
            <img src="/images/pennyworld/stone.png" alt="" class="w-6 h-6" />
            <p class="text-white font-bold">10</p>
          </div>
          <div class="flex p-1 gap-2 rounded">
            <img src="/images/pennyworld/gold.png" alt="" class="w-6 h-6" />
            <p class="text-white font-bold">5</p>
          </div>
        </div>
      </div>
      <div class="text-white p-2">
        <p class="text-lg">Annoucement</p>
        <div class="w-full overflow overflow-none">
          <p class="flex flex-shrink-0 announcement">
            <span class="-ml-2">Flip card game coming soon !! </span>
          </p>
        </div>
      </div>
      <div class="px-2 text-sm">
        <button class="bg-stone-800 p-2 rounded text-white">Previous result</button>
      </div>
      <div class="pt-2">
        <div class="flex flex-col items-center px-2 h-[100vh] overflow-y-auto pb-6">
          <!-- <p class="text-white py-4">Click on any card to flip</p> -->
          <div class="grid grid-cols-6 gap-2">
            <div
              v-for="(card, index) in shuffledCards"
              :key="index"
              class="relative cursor-pointer shuffle"
              @click="handleCardClick(index)"
            >
              <div
                class="shadow-md transform transition-transform duration-500"
                :class="isFlipped(index)"
              >
                <img
                  class="w-full h-full object-cover rounded-lg"
                  :src="isFlipped(index) ? card.front : card.back"
                  alt="Card"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.custom-bg {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}

@media (max-width: 1024px) {
  .custom-bg {
    background-size: cover;
    background-position: 75% center;
  }
}

@media (max-width: 640px) {
  .custom-bg {
    background-size: cover;
    background-position: 75% center;
  }
}

.shuffle {
  animation: shuffle 1.5s ease-out;
}

@keyframes shuffle {
  0%,
  100% {
    opacity: 1;
    transform: rotate(0);
  }

  25% {
    opacity: 0;
    transform: rotate(90deg);
  }

  50% {
    opacity: 0;
    transform: rotate(180deg);
  }

  75% {
    opacity: 0;
    transform: rotate(270deg);
  }
}

.flip-card {
  perspective: 1000px;
  transition: transform 0.5s;
}

.flipped {
  transform: rotateY(180deg);
}

.announcement {
  white-space: nowrap; /* prevent text wrapping */
  animation: move-left 7s linear infinite;
}

@keyframes move-left {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(-100%);
  }
}

.zoom-flip {
  animation: zoom-flip 1s;
}

@keyframes zoom-flip {
  0% {
    transform: scale(1) rotateY(0deg);
  }
  20% {
    transform: scale(1.4) rotateY(0deg);
  }
  50% {
    transform: scale(2) rotateY(180deg);
  }
  80% {
    transform: scale(1.4) rotateY(180deg);
  }
  100% {
    transform: scale(1) rotateY(180deg);
  }
}
</style>
