import revive_payload_client_4sVQNw7RlN from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_A0OWXRrUgq from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/home/runner/work/pennywhales-tma/pennywhales-tma/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import switch_locale_path_ssr_5csfIgkrBP from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import route_locale_detect_VrpP1JaYg8 from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@nuxtjs/i18n/dist/runtime/plugins/route-locale-detect.js";
import i18n_sq1MuCrqbC from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import plugin_ucMK8VhJjR from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/nuxt-easy-lightbox/dist/runtime/plugin.mjs";
import sentry_client_VdOeuw25Mb from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/home/runner/work/pennywhales-tma/pennywhales-tma/.nuxt/sentry-client-config.mjs";
import plugin_WLsn00x1qh from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import motion_fmZWau4t0U from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@vueuse/motion/dist/runtime/templates/motion.mjs";
import vue3_touch_events_3JrctwDJKG from "/home/runner/work/pennywhales-tma/pennywhales-tma/plugins/vue3-touch-events.ts";
import ssg_detect_3fHkBxLtv0 from "/home/runner/work/pennywhales-tma/pennywhales-tma/node_modules/@nuxtjs/i18n/dist/runtime/plugins/ssg-detect.js";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  switch_locale_path_ssr_5csfIgkrBP,
  route_locale_detect_VrpP1JaYg8,
  i18n_sq1MuCrqbC,
  plugin_ucMK8VhJjR,
  sentry_client_VdOeuw25Mb,
  sentry_client_config_o34nk2sJbg,
  plugin_WLsn00x1qh,
  motion_fmZWau4t0U,
  vue3_touch_events_3JrctwDJKG,
  ssg_detect_3fHkBxLtv0
]