<script setup lang="ts">
const { sendBroadcastTestAction, connectToRoom, disconnectRoom, roomList, connectStatus } =
  useSocket();
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { navigateToPage, customRouterState } = useCustomRouter();
const showDropdown = ref(false);
const handleDropdown = () => {
  console.log(showDropdown.value);
  showDropdown.value = !showDropdown.value;
};

onMounted(async () => {
  // setTimeout(() => {
  //   sendBroadcastTestAction();
  // }, 5000);
});
</script>

<template>
  <div class="h-screen bg-[url('/images/pennyworld/ocean.png')] bg-top">
    <div class="flex flex-row justify-between px-4 pt-6 pb-4">
      <img
        src="/images/pennyworld/World.png"
        alt=""
        class="w-10 h-10"
        @click="navigateToPage('pennyworld')"
      />
      <div class="reverse flex flex-row-reverse gap-4">
        <div class="flex p-1 gap-2 rounded">
          <img src="/images/pennyworld/stone.png" alt="" class="w-6 h-6" />
          <p class="text-white font-bold">10</p>
        </div>
        <div class="flex p-1 gap-2 rounded">
          <img src="/images/pennyworld/gold.png" alt="" class="w-6 h-6" />
          <p class="text-white font-bold">5</p>
        </div>
      </div>
    </div>
    <!-- page title -->
    <div class="pt-8">
      <p class="text-white font-bold text-center font-bold text-3xl">FLIP CARD</p>
    </div>
    <!-- <button @click="sendBroadcastTestAction">Send message</button> -->
    <div class="flex flex-col gap-6 text-white text-center text-2xl px-4 mt-12">
      <div v-for="(item, index) in roomList?.data" :key="index" class="w-full">
        <div class="bg-amber-300 p-2 rounded" @click="connectToRoom(item.id)">
          <p>{{ item.name }}</p>
        </div>
      </div>
    </div>
    <!-- <button @click="disconnectRoom">Disconnect</button> -->
  </div>
</template>
