<script setup lang="ts">
const { customRouterState, navigateToPage } = useCustomRouter();

import { useWebAppNavigation } from 'vue-tg';
const { openLink } = useWebAppNavigation();

const images = [
  {
    imageUrl: '/images/campaign_banner.png',
    url: 'leaderboard',
    internal: true,
    alt: 'Campaign Banner',
  },
  {
    imageUrl: '/images/ads_binance.png',
    url: 'https://www.binance.info/activity/referral-entry/CPA?ref=CPA_00T6SKJ3U0',
    internal: false,
    alt: 'Binance Banner',
  },
  {
    imageUrl: '/images/ads_reddotpay.jpeg',
    url: 'https://url.hk/i/en/4jemj',
    internal: false,
    alt: 'Reddotpay Banner',
  },
  {
    imageUrl: '/images/ads_gateio.webp',
    url: 'https://www.gate.io/signup/XlFFUlla?ref_type=103',
    internal: false,
    alt: 'Gateio Banner',
  },
];

const currentIndex = ref(0);
const slider = ref(null);
let startX = 0;
let endX = 0;

const prev = () => {
  currentIndex.value = (currentIndex.value - 1 + images.length) % images.length;
  startX = 0;
  endX = 0;
};

const next = () => {
  currentIndex.value = (currentIndex.value + 1) % images.length;
  startX = 0;
  endX = 0;
};

const handleTouchStart = (event) => {
  startX = event.touches[0].clientX;
};

const handleTouchMove = (event) => {
  endX = event.touches[0].clientX;
};

const handleTouchEnd = () => {
  if (endX === 0) return;
  if (startX - endX > 50) {
    next();
  } else if (endX - startX > 50) {
    prev();
  }
};

const bannerClicked = (campaign) => {
  if (campaign.internal) {
    navigateToPage(campaign.url);
  } else {
    openLink(campaign.url);
  }
};

onMounted(() => {
  const sliderContainer = document.querySelector('.slider-container');
  sliderContainer?.addEventListener('touchstart', handleTouchStart);
  sliderContainer?.addEventListener('touchmove', handleTouchMove);
  sliderContainer?.addEventListener('touchend', handleTouchEnd);
});

onBeforeUnmount(() => {
  const sliderContainer = document.querySelector('.slider-container');
  sliderContainer?.removeEventListener('touchstart', handleTouchStart);
  sliderContainer?.removeEventListener('touchmove', handleTouchMove);
  sliderContainer?.removeEventListener('touchend', handleTouchEnd);
});
</script>
<template>
  <div class="relative w-full overflow-hidden slider-container">
    <div
      ref="slider"
      class="flex transition-transform duration-500"
      :style="{ transform: `translateX(-${currentIndex * 100}%)` }"
    >
      <div v-for="(campaign, index) in images" :key="index" class="w-full flex-shrink-0">
        <div class="aspect-video" @click="bannerClicked(campaign)">
          <img
            :src="campaign.imageUrl"
            :alt="campaign.alt"
            class="w-full h-full object-cover rounded-2xl border-4"
          />
        </div>
      </div>
    </div>
    <div class="flex justify-center space-x-2 mt-2">
      <button
        v-for="(image, index) in images"
        :key="index"
        @click="currentIndex = index"
        class="w-2 h-2 rounded-full"
        :class="currentIndex === index ? 'bg-gray-300' : 'bg-gray-700'"
      ></button>
    </div>
    <button
      @click="prev"
      class="absolute left-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white py-8 bg-transparent cursor-pointer opacity-0"
    >
      Prev
    </button>
    <button
      @click="next"
      class="absolute right-0 top-1/2 transform -translate-y-1/2 bg-gray-700 text-white py-8 bg-transparent opacity-0"
    >
      Next
    </button>
  </div>
</template>
