const useSocket = () => {
  const roomList = ref([]);
  const connectStatus = ref([]);
  console.log('useSocket called');
  // Create WebSocket connection.
  const socket = new WebSocket(
    'wss://websocket-staging.pennywhale.io?dataToValidate=user=%7B%22id%22%3A537670647%2C%22first_name%22%3A%22Ji%20sheng%22%2C%22last_name%22%3A%22%22%2C%22username%22%3A%22jisheng%22%2C%22language_code%22%3A%22en%22%2C%22is_premium%22%3Atrue%2C%22allows_write_to_pm%22%3Atrue%7D%26chat_instance=576844549226864497%26chat_type=sender%26start_param=PENNYWHALE2049_537670647%26auth_date=1726632549%26hash=02646e26ab610e81d92093289bfcd0256b90c461c96a3e561034fccf87079d4c',
  );
  // Listen for all messages
  socket.addEventListener('message', (event) => {
    console.log('Message from server ', event);
    const data = JSON.parse(event.data);
    switch (data.action) {
      case 'GetGameRooms':
        roomList.value = JSON.parse(event.data);
        console.log('Data from server ', roomList.value);
        break;
      case 'ConnectRoom':
        connectStatus.value = JSON.parse(event.data);
        console.log('Connected to room ', data.data.message);
        console.log('Connected to room ', connectStatus.value);
        break;
      default:
        console.log('Unknown action ', data);
    }
  });

  // Send BroadcastTest action

  const sendBroadcastTestAction = () => {
    if (socket.readyState === WebSocket.OPEN) {
      console.log('Sending BroadcastTest action from composables');
      const action = {
        action: 'GetGameRooms',
        msgId: '6503607aaf99d8f33bec65d2',
        requestPayload: {
          roomType: 'flip-game',
        },
      };
      socket.send(JSON.stringify(action));
    } else {
      console.log('WebSocket connection is not open. Cannot send message.');
    }
  };

  const connectToRoom = (Id: string) => {
    console.log('Connecting to room ', Id);
    const action = {
      action: 'ConnectRoom',
      msgId: '6503607aaf99d8f33bec65d2',
      requestPayload: {
        roomId: Id,
      },
    };
    socket.send(JSON.stringify(action));
  };

  const disconnectRoom = () => {
    console.log('Disconnecting from room');
    const action = {
      action: 'DisconnectRoom',
      msgId: '6503607aaf99d8f33bec65d2',
      requestPayload: {},
    };
    socket.send(JSON.stringify(action));
  };

  return {
    sendBroadcastTestAction,
    connectToRoom,
    disconnectRoom,
    roomList,
    connectStatus,
  };
};

export default useSocket;
