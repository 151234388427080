import { Body } from '#build/components';
import { defineStore, skipHydrate } from 'pinia';
const { encryptMessage, randomUUID } = useEncryption();

const apiUrl =
  window.location.hostname === 'localhost' ||
  window.location.hostname === 'staging.pennywhale-twa.pages.dev'
    ? 'https://api-staging.pennywhale.io'
    : 'https://api.pennywhale.io';
const apiKey = '3b3a8314-45da-4d33-8e44-a7618eacd479';
const redotKey = '69af04e5-ff5b-4dc5-a210-f82a5cebcb35';

interface IAuthState {
  isAuthenticated: boolean;
  authString: string;
  username: string;
  firstName: string;
  tgId: number;
  point: number;
  pointsToAdd: number;
  level: number;
  energy: number;
  boosterEnergy: number;
  totalPoint: number;
  maximumEnergy: number;
  tapPower: number;
  lastTapAt: string;
  isJoinCampaign: boolean;
  campaignTotalPoint: number;
  secretCampaignJoin: boolean;
  rawWalletAddress: string;
  claimS1Rewards: boolean;
  bannerclaimS1Rewards: boolean;
  usdtBalance: number;
  isRegister: boolean;
  redotPayId: string;
  haveSendSelfie: boolean;
  haveRedotPaySS: boolean;
  accumulatedPointsMining: number;
  maxMiningPoints: number;
  accumulatedEnergy: number;
  taskStatusOverall: string;
  energyNextLvl: number;
  multiTapNextLvl: number;
  energyCostUpgrade: number;
  multiTapCostUpgrade: number;
  premiumBoosterQuantity: number;
  tappingAssistantQuantity: number;
  shellCount: number;
  guessBitcoinPoint: number;
  nextDateToAnswerQuestion: string;
  isJoinS2Season: boolean;
  isBanned: false;
  isClaimS2Pennypool: boolean;
  isClaimS2Btcpool: boolean;
  nextEnergyCapacity: number;
  costPointToUpgradeEnergy: number;
  nextEnergyLevel: number;
  nextTapPower: number;
  nextTapLevel: number;
  costPointToUpgradeTap: number;
  hashRateBalance: number;
  bscWalletAddress: string;
  language: string;
  isSetLanguageFirstTimer: boolean;
  isWelcome: boolean;
  hashratePassiveBalance: number;
}

const STORAGE_NAME = 'auth';
const DEFAULT_STATE: IAuthState = {
  isAuthenticated: false,
  authString: '',
  username: '',
  firstName: '',
  tgId: 0,
  point: 0,
  pointsToAdd: 0,
  level: 0,
  energy: 0,
  boosterEnergy: 0,
  totalPoint: 0,
  maximumEnergy: 0,
  tapPower: 0,
  lastTapAt: '',
  isJoinCampaign: false,
  campaignTotalPoint: 0,
  secretCampaignJoin: false,
  rawWalletAddress: '',
  claimS1Rewards: false,
  bannerclaimS1Rewards: false,
  usdtBalance: 0,
  isRegister: false,
  redotPayId: '',
  haveSendSelfie: false,
  haveRedotPaySS: false,
  accumulatedPointsMining: 0,
  maxMiningPoints: 0,
  accumulatedEnergy: 0,
  taskStatusOverall: '',
  energyNextLvl: 0,
  multiTapNextLvl: 0,
  energyCostUpgrade: 0,
  multiTapCostUpgrade: 0,
  premiumBoosterQuantity: 0,
  tappingAssistantQuantity: 0,
  shellCount: 0,
  guessBitcoinPoint: 0,
  nextDateToAnswerQuestion: '',
  isJoinS2Season: false,
  isBanned: false,
  isClaimS2Pennypool: false,
  isClaimS2Btcpool: false,
  nextEnergyCapacity: 0,
  costPointToUpgradeEnergy: 0,
  nextEnergyLevel: 0,
  nextTapPower: 0,
  nextTapLevel: 0,
  costPointToUpgradeTap: 0,
  hashRateBalance: 0,
  bscWalletAddress: '',
  language: '',
  isSetLanguageFirstTimer: false,
  isWelcome: false,
  hashratePassiveBalance: 0,
};

export const useAuthStore = defineStore('useAuthStore', () => {
  // States
  const authState = ref<IAuthState>(DEFAULT_STATE);

  // Methods
  const updateState = (newState: IAuthState) => {
    authState.value = newState;
    if (process.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(newState));
    }
  };

  const resetState = () => {
    authState.value = DEFAULT_STATE;
    if (process.client) {
      window.localStorage.setItem(STORAGE_NAME, JSON.stringify(DEFAULT_STATE));
    }
  };

  const signIn = async (authString: string) => {
    const parsedData = Object.fromEntries(new URLSearchParams(authString));

    const user = JSON.parse(parsedData.user);
    const {
      data: response,
      error: userError,
      refresh: userRefresh,
    } = await useFetch(`${apiUrl}/api/penny-whale/webapp/users/details`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiKey,
      },
      body: {
        dataToValidate: authString,
      },
    });

    if (response) {
      const tapUpgradeDetails = response.value.tapUpgradeDetails;
      const energyUpgradeDetails = response.value.energyUpgradeDetails;
      //console.log('maximumEnergy',response.value.maximumEnergy,);

      updateState({
        ...authState.value,
        isAuthenticated: true,
        authString: authString,
        username: user.username,
        firstName: user.first_name,
        tgId: user.id,
        language: response.value.preferredLanguage,
        point: response.value.point,
        level: response.value.level,
        energy: response.value.energy,
        boosterEnergy: response.value.boosterEnergy,
        totalPoint: response.value.totalPoint,
        maximumEnergy: response.value.maximumEnergy,
        tapPower: response.value.tapPower,
        lastTapAt: response.value.lastTapAt,
        isJoinCampaign: response.value.isJoinCampaign,
        campaignTotalPoint: response.value.campaignTotalPoint,
        rawWalletAddress: response.value.rawWalletAddress,
        claimS1Rewards: response.value.claimS1Rewards,
        isRegister: response.value.isRegister,
        redotPayId: response.value.redotPayId,
        haveSendSelfie: response.value.haveSendSelfie,
        haveRedotPaySS: response.value.haveRedotPaySS,
        premiumBoosterQuantity: response.value.premiumBoosterQuantity,
        tappingAssistantQuantity: response.value.tappingAssistantQuantity,
        shellCount: response.value.shellCount,
        guessBitcoinPoint: response.value.guessBitcoinPoint,
        nextDateToAnswerQuestion: response.value.nextDateToAnswerQuestion,
        isJoinS2Season: response.value.isJoinS2Season,
        isBanned: response.value.isBanned,
        isClaimS2Pennypool: response.value.isClaimS2Pennypool,
        isClaimS2Btcpool: response.value.isClaimS2Btcpool,
        nextEnergyCapacity: energyUpgradeDetails.nextEnergyCapacity,
        costPointToUpgradeEnergy: energyUpgradeDetails.costPointToUpgrade,
        nextEnergyLevel: energyUpgradeDetails.nextLevel,
        nextTapPower: tapUpgradeDetails.tapPower,
        nextTapLevel: tapUpgradeDetails.nextLevel,
        costPointToUpgradeTap: tapUpgradeDetails.costPointToUpgrade,
        hashRateBalance: response.value.hashRateBalance,
        usdtBalance: response.value.usdtBalance,
        bscWalletAddress: response?.value.bscWalletAddress,
        isSetLanguageFirstTimer: response.value.isSetLanguageFirstTimer,
        isWelcome: response.value.isWelcome,
        hashratePassiveBalance: response.value.hashratePassiveBalance,
      });
    }
    if (userError) {
      return;
    }
  };

  const campaignAutoJoin = async (campaigncode: string, refCode: string, authString: string) => {
    const parsedData = Object.fromEntries(new URLSearchParams(authString));
    const user = JSON.parse(parsedData.user);
    const response = await $fetch(`${apiUrl}/api/penny-whale/webapp/users/join-secret-campaign`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiKey,
      },
      body: {
        dataToValidate: authString,
        campaignCode: campaigncode,
        referralCode: refCode,
      },
      ignoreResponseError: true,
    });
    if (response?.statusCode === 400) {
      updateState({
        ...authState.value,
        secretCampaignJoin: false,
      });
      signIn(authString);
    } else {
      updateState({
        ...authState.value,
        secretCampaignJoin: response?.status,
      });
      signIn(authString);
      if (response?.status) {
      }
    }
  };

  const secretWhale = async (whalecode: string, refCode: string, authString: string) => {
    const response = await $fetch(`${apiUrl}/api/penny-whale/webapp/users/found-secret-whale`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiKey,
      },
      body: {
        dataToValidate: authString,
        secretCode: whalecode,
        referralCode: refCode,
      },
      ignoreResponseError: true,
    });
    signIn(authString);
  };

  const resetRewardClaimed = async () => {
    updateState({
      ...authState.value,
      bannerclaimS1Rewards: true,
    });
  };

  const updateHaveSendSelfie = async () => {
    updateState({
      ...authState.value,
      haveSendSelfie: true,
    });
  };

  const updateHaveRedotPaySS = async () => {
    updateState({
      ...authState.value,
      haveRedotPaySS: true,
    });
  };

  const updateIsRegisterRedotpay = async () => {
    updateState({
      ...authState.value,
      isRegister: true,
    });
  };
  const updateRedotPayId = async (id: string) => {
    updateState({
      ...authState.value,
      redotPayId: id,
    });
  };

  const updateAuthStatePoint = (point: number) => {
    updateState({
      ...authState.value,
      point: point,
    });
  };

  const updateMiningDetails = (point: number, maxPoints) => {
    updateState({
      ...authState.value,
      accumulatedPointsMining: point,
      maxMiningPoints: maxPoints,
    });
  };

  const updateAccumulatedEnergy = (energy: number) => {
    updateState({
      ...authState.value,
      accumulatedEnergy: energy,
    });
  };

  const updateTaskStatusOverall = (status: string) => {
    updateState({
      ...authState.value,
      taskStatusOverall: status,
    });
  };

  const updateBoosterData = (
    newenergyNextLvl: number,
    newenergyCostUpgrade: number,
    newmultiTapNextLvl: number,
    newmultiTapCostUpgrade: number,
  ) => {
    updateState({
      ...authState.value,
      energyNextLvl: newenergyNextLvl,
      multiTapNextLvl: newmultiTapNextLvl,
      energyCostUpgrade: newenergyCostUpgrade,
      multiTapCostUpgrade: newmultiTapCostUpgrade,
    });
  };

  const updatePremiumBoosterEnergy = (balPremiumEnergy: number, balenergy: number) => {
    updateState({
      ...authState.value,
      premiumBoosterQuantity: balPremiumEnergy,
      energy: balenergy,
    });
  };

  const updatePremiumTapAssistant = (balTap: number, balpoint: number) => {
    updateState({
      ...authState.value,
      tappingAssistantQuantity: balTap,
      point: balpoint,
    });
  };
  const updateGuessBTC = (guessBtcPoint: number, shellCount: number) => {
    updateState({
      ...authState.value,
      guessBitcoinPoint: guessBtcPoint,
      shellCount: shellCount,
    });
  };

  const updatePoint = async (point: number) => {
    const messageDetails = JSON.stringify({
      point: point,
    });
    const encryptedMessage = await encryptMessage(messageDetails);

    const response = await $fetch(`${apiUrl}/api/penny-whale/webapp/users/add-point-v2`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': apiKey,
      },
      body: {
        dataToValidate: authState.value.authString,
        encryptBody: encryptedMessage,
      },
      ignoreResponseError: true,
    });

    if (response.statusCode == 400 || response.error) {
      return;
    }

    updateState({
      ...authState.value,
      point: response.point,
      energy: response.energy,
      totalPoint: response.totalPoint,
      lastTapAt: response.lastTapAt,
      campaignTotalPoint: response.campaignTotalPoint,
    });
    return response;
  };

  const updateQuestionaireResult = (shellCount: number, nextDate: string) => {
    updateState({
      ...authState.value,
      shellCount: shellCount,
      nextDateToAnswerQuestion: nextDate,
    });
  };

  const updatePurchase = (
    newpremiumBoosterQuantity: number,
    newtappingAssistantQuantity: number,
    newshellCount: number,
    newusdtBalance: number,
  ) => {
    updateState({
      ...authState.value,
      premiumBoosterQuantity: newpremiumBoosterQuantity,
      tappingAssistantQuantity: newtappingAssistantQuantity,
      shellCount: newshellCount,
      usdtBalance: newusdtBalance,
    });
  };

  const updateRawWalletAddress = (rawAddress: string) => {
    updateState({
      ...authState.value,
      rawWalletAddress: rawAddress,
    });
  };
  const updateResetRedotPay = () => {
    updateState({
      ...authState.value,
      isRegister: false,
      redotPayId: '',
      haveRedotPaySS: false,
      haveSendSelfie: false,
    });
  };

  const refreshSignIn = () => {
    if (authState.value.isAuthenticated) {
      signIn(authState.value.authString);
    }
  };

  const updateUsdtBalance = (usdtbalance: number, status: boolean, Type: string) => {
    if (Type === 'btcguessPool') {
      updateState({
        ...authState.value,
        usdtBalance: usdtbalance,
        isClaimS2Btcpool: status,
      });
    } else if (Type === 'pennywhalePool') {
      updateState({
        ...authState.value,
        usdtBalance: usdtbalance,
        isClaimS2Pennypool: status,
      });
    }
  };

  const isClaimed = async () => {
    const response = await $fetch(
      `${apiUrl}/api/penny-whale/webapp/campaign/is-user-claim-s2-reward`,
      {
        headers: {
          'X-API-KEY': apiKey,
        },
        query: {
          dataToValidate: authState.value.authString,
          tgUserId: authState.value.tgId,
        },
      },
    );

    if (response) {
      updateState({
        ...authState.value,
        isClaimS2Pennypool: response.isClaimS2Pennypool,
        isClaimS2Btcpool: response.isClaimS2Btcpool,
      });
    }
  };

  const updateHashRateBalance = (
    newHashRate: number,
    newusdtBalance: number,
    newHashratePassiveBalance: number,
  ) => {
    updateState({
      ...authState.value,
      hashRateBalance: newHashRate,
      usdtBalance: newusdtBalance,
      hashratePassiveBalance: newHashratePassiveBalance,
    });
  };

  const updateBscWalletAddress = (newBscWalletAddress: string) => {
    updateState({
      ...authState.value,
      bscWalletAddress: newBscWalletAddress,
    });
  };

  const updatePreferredLang = (newLang: string) => {
    updateState({
      ...authState.value,
      language: newLang,
    });
    console.log('current lang', authState.value.language);
  };

  const updateIsWelcome = (isWelcome: boolean) => {
    updateState({
      ...authState.value,
      isWelcome: isWelcome,
    });
  };

  const updateTapPower = (newTapPower: number) => {
    updateState({
      ...authState.value,
      tapPower: newTapPower,
    });
  };

  const updatepointsToAdd = (pointsToAdd: number) => {
    updateState({
      ...authState.value,
      pointsToAdd: pointsToAdd,
    });
  };

  return {
    authState: skipHydrate(authState),
    signIn,
    updatePoint,
    apiKey,
    apiUrl,
    redotKey,
    refreshSignIn,
    campaignAutoJoin,
    resetRewardClaimed,
    updateRawWalletAddress,
    updateAuthStatePoint,
    updateHaveSendSelfie,
    updateHaveRedotPaySS,
    updateIsRegisterRedotpay,
    updateRedotPayId,
    updateMiningDetails,
    updateAccumulatedEnergy,
    updateTaskStatusOverall,
    updateBoosterData,
    secretWhale,
    updatePremiumBoosterEnergy,
    updatePremiumTapAssistant,
    updateGuessBTC,
    updateQuestionaireResult,
    updatePurchase,
    updateResetRedotPay,
    isClaimed,
    updateUsdtBalance,
    updateHashRateBalance,
    updateBscWalletAddress,
    updatePreferredLang,
    updateIsWelcome,
    updateTapPower,
    updatepointsToAdd,
  };
});
