<script setup lang="ts">
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { navigateToPage } = useCustomRouter();
const showDropdown = ref(false);
const handleDropdown = () => {
  console.log(showDropdown.value);
  showDropdown.value = !showDropdown.value;
};
</script>

<template>
  <div class="h-screen bg-[url('/images/pennyworld/ocean.png')] bg-top">
    <!-- page header -->
    <div class="px-2 z-10 flex flex-row justify-between overscroll-none">
      <div class="flex flex-row justify-between px-2 pt-4">
        <img
          src="/images/pennyworld/World.png"
          alt=""
          class="w-10 h-10"
          @click="navigateToPage('pennyworld')"
        />
      </div>
      <div class="reverse flex flex-row-reverse gap-4 pt-4">
        <div class="flex p-1 gap-2 rounded">
          <img src="/images/pennyworld/stone.png" alt="" class="w-6 h-6" />
          <p class="text-white font-bold">10</p>
        </div>
        <div class="flex p-1 gap-2 rounded">
          <img src="/images/pennyworld/gold.png" alt="" class="w-6 h-6" />
          <p class="text-white font-bold">5</p>
        </div>
      </div>
    </div>
    <!-- page title -->
    <div class="pt-14">
      <p class="text-white font-bold text-center font-bold text-3xl font-avenger">ARCADE</p>
    </div>
    <div
      class="flex flex-col justify-center gap-6 items-center text-white text-center text-2xl m-4 mt-12"
    >
      <div class="bg-amber-300 p-2 rounded w-3/4" @click="navigateToPage('arcaderoom')">
        <p>FLIP CARD GAME</p>
      </div>
      <div class="bg-amber-300 p-2 rounded w-3/4" @click="navigateToPage('mainguessbtc')">
        <p>Guees the BTC price</p>
      </div>
    </div>
  </div>
</template>
