const useStagingEnv = () => {
  const isStagingEnv = useState('isStagingEnv', () => false);

  const setStagingEnv = (value: boolean) => {
    console.log('setStagingEnv', value);
    isStagingEnv.value = value;
  };

  return {
    isStagingEnv,
    setStagingEnv,
  };
};

export default useStagingEnv;
