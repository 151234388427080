<script setup lang="ts">
import { useWebAppHapticFeedback, useWebAppNavigation } from 'vue-tg';
import { useI18n } from 'vue-i18n';
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { customRouterState, navigateToPage } = useCustomRouter();
const { openLink } = useWebAppNavigation();
const { updatePreferredLang, updateIsWelcome } = authStore;
const { locale } = useI18n();
const { addNewSuccessToast } = useToast();
const showPrompt = ref(false);
const showTutorial = ref(false);

const currentTutorialIndex = ref(0);
const tutorialSlides = [
  'mining_pool',
  'communities',
  'fun_fair',
  'asset_vault',
  'resources_center',
  'meme_rush',
];

const isExplode = ref(false);
const handleClick = (pageTo: string) => {
  showPrompt.value = false;
  useWebAppHapticFeedback();
  isExplode.value = true;

  setTimeout(() => {
    isExplode.value = false;
    if (pageTo === 'arcadeisland') {
      navigateToPage('arcadeisland');
    }
    if (pageTo === 'treasure') {
      navigateToPage('treasure');
    }
    if (pageTo === 'memerush') {
      openLink('https://pennywhale.io/pepewhale');
    }
    if (pageTo === 'hashminingisland') {
      navigateToPage('hashminingisland');
    }
    if (pageTo === 'communityisland') {
      navigateToPage('communityisland');
    }
    if (pageTo === 'assetvault') {
      navigateToPage('assetvault');
    }
  }, 900);
};

const centerScroll = () => {
  const scrollContainer = document.querySelector('.overflow-x-auto');
  if (scrollContainer) {
    const centerX = scrollContainer.scrollWidth / 4;
    scrollContainer.scrollLeft = centerX;
  }
};

const setNewLang = async (input: string) => {
  const response = await $fetch(
    `${authStore.apiUrl}/api/penny-whale/webapp/users/set-preferred-language`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': authStore.apiKey,
      },
      body: {
        dataToValidate: authState.value.authString,
        preferredLanguage: input,
      },
      ignoreResponseError: true,
    },
  );
  if (response?.statusCode == 200) {
    await updatePreferredLang(input);
    locale.value = authState.value.language;
    addNewSuccessToast('Language has been updated', 'success');
    showPrompt.value = false;
    return;
  }
  if (response?.statusCode !== 200) {
    showPrompt.value = false;
    return;
  }
};

const completeTutorial = async () => {
  if (authState.value.isWelcome) {
    showTutorial.value = false;
    currentTutorialIndex.value = 0;
  } else {
    const response = await $fetch(
      `${authStore.apiUrl}/api/penny-whale/webapp/users/set-user-is-welcomed`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-API-KEY': authStore.apiKey,
        },
        body: {
          dataToValidate: authState.value.authString,
        },
        ignoreResponseError: true,
      },
    );
    if (response?.statusCode === 200) {
      await updateIsWelcome(response?.isWelcome);
      addNewSuccessToast('You have completed welcoming tutorial.');
      showTutorial.value = false;
      currentTutorialIndex.value = 0;
      return;
    } else if (response?.statusCode !== 200) {
      await updateIsWelcome(false);
      currentTutorialIndex.value = 0;
      return;
    }
  }
};

const checkLanguage = () => {
  if (authState.value.isSetLanguageFirstTimer) {
    showPrompt.value = true;
  } else {
    showPrompt.value = false;
  }
};

const checkIsWelcome = () => {
  if (authState.value.isWelcome) {
    showTutorial.value = false;
  } else {
    showTutorial.value = true;
  }
};

const nextTutorialSlide = () => {
  if (currentTutorialIndex.value < tutorialSlides.length - 1) {
    currentTutorialIndex.value = currentTutorialIndex.value + 1;
  } else {
    completeTutorial();
  }
};

onMounted(() => {
  centerScroll();
  checkLanguage();
  checkIsWelcome();
});
</script>

<template>
  <div class="relative w-full h-screen overflow-x-auto">
    <div v-if="isExplode" class="absolute bg-white opacity-30 blur-effect z-30 w-[200vw]"></div>
    <div
      class="bg-[url('/images/pennyworld/ocean.png')] bg-no-repeat bg-cover bg-center w-[200vw] h-screen relative z-0"
      :class="{ blureffect: isExplode }"
    >
      <div
        v-if="showPrompt"
        class="fixed inset-0 z-40 flex items-center justify-center scale-75 bg-center bg-no-repeat bg-cover"
        style="background-image: url('/images/pennyworld/bg_welcome.png')"
      >
        <div class="flex flex-col items-center justify-center">
          <div class="flex items-center justify-center gap-10 mt-[44vh]">
            <button @click="setNewLang('en')">
              <img src="/images/pennyworld/english.png" alt="" class="w-full h-full scale-125" />
            </button>
            <button @click="setNewLang('zh-hans')">
              <img src="/images/pennyworld/zh-hans.png" class="w-full h-full scale-125" />
            </button>
          </div>
        </div>
      </div>

      <div v-if="showTutorial" class="fixed inset-0 z-40 flex items-center justify-center">
        <button @click="nextTutorialSlide()" class="flex items-center justify-center">
          <img
            :src="
              authState.language === 'zh-hans'
                ? `/images/pennyworld/tutorial_${tutorialSlides[currentTutorialIndex]}_chinese.png`
                : `/images/pennyworld/tutorial_${tutorialSlides[currentTutorialIndex]}.png`
            "
            alt="Tutorial Slide"
            class="w-4/5"
          />
        </button>
      </div>

      <img
        v-show="!isExplode"
        src="/images/pennyworld/PennyWhaleWorld_title_all.png"
        alt="Pennyworld title"
        class="fixed z-30 transform -translate-x-1/2 top-10 left-1/2"
      />
      <img
        :src="
          authState.language === 'zh-hans'
            ? '/images/pennyworld/PWW_MemeRush_c.png'
            : '/images/pennyworld/PWW_MemeRush.png'
        "
        alt="Memerush"
        @click="authState.isWelcome && !showPrompt && handleClick('memerush')"
        class="absolute z-20 transform -translate-x-4 -translate-y-1/2 w-28 top-1/3 right-1/4"
      />
      <img
        src="/images/pennyworld/island1-removebg.png"
        alt="arcade hide"
        class="absolute z-20 transform translate-x-10 -translate-y-2 w-28 top-1/4 left-1/3"
      />
      <!-- <img
        :src="
          authState.language === 'zh-hans'
            ? '/images/pennyworld/PWW_Arcade.png_c.png'
            : '/images/pennyworld/PWW_Arcade.png'
        "
        alt="Arcade"
        @click="handleClick('arcadeisland')"
        class="absolute z-20 transform translate-x-12 -translate-y-2 w-28 top-1/4 left-1/3"
      /> -->
      <img
        :src="
          authState.language === 'zh-hans'
            ? '/images/pennyworld/PWW_Funfair_c.png'
            : '/images/pennyworld/PWW_Funfair.png'
        "
        alt="Funfair"
        @click="authState.isWelcome && !showPrompt && handleClick('treasure')"
        class="absolute w-40 transform translate-x-1/2 -translate-y-1/2 top-1/2 right-1/4"
      />
      <img
        :src="
          authState.language === 'zh-hans'
            ? '/images/pennyworld/PWW_Mining_c.png'
            : '/images/pennyworld/PWW_Mining_pool.png'
        "
        alt="hashminingisland"
        @click="authState.isWelcome && !showPrompt && handleClick('hashminingisland')"
        class="absolute mt-20 transform -translate-x-1/2 w-52 top-1/2 left-1/2 -translate-y-1/4"
      />
      <img
        :src="
          authState.language === 'zh-hans'
            ? '/images/pennyworld/PWW_Communities_C.png'
            : '/images/pennyworld/PWW_Communities.png'
        "
        alt="community"
        @click="authState.isWelcome && !showPrompt && handleClick('communityisland')"
        class="absolute z-20 w-48 transform -translate-x-1/2 top-1/3 left-1/2 translate-y-1/4"
      />
      <img
        :src="
          authState.language === 'zh-hans'
            ? '/images/pennyworld/PWW_AssetVault_c.png'
            : '/images/pennyworld/PWW_AssetVault.png'
        "
        alt="wallet"
        @click="authState.isWelcome && !showPrompt && handleClick('assetvault')"
        class="absolute z-20 w-32 transform -translate-y-20 top-1/2 left-1/4 -translate-x-1/4"
      />

      <!-- <img
        src="/images/pennyworld/island1-removebg.png"
        alt="resources in construction"
        class="absolute z-20 w-24 transform -translate-x-12 -translate-y-1/2 top-1/3 left-1/4"
      /> -->

      <img
        :src="
          authState.language === 'zh-hans'
            ? '/images/pennyworld/resources_center_c.png'
            : '/images/pennyworld/resources_center.png'
        "
        alt="resources center"
        @click="showTutorial = true"
        class="absolute z-20 transform -translate-x-6 -translate-y-1/2 w-28 top-1/3 left-1/4"
      />

      <!-- empty island section -->
      <img
        src="/images/pennyworld/island1-removebg.png"
        alt=""
        class="absolute z-20 w-20 transform -translate-y-10 skew-x-12 top-1/4 left-1/2"
      />
      <img
        src="/images/pennyworld/island5-removebg.png"
        alt=""
        class="absolute transform translate-x-24 -translate-y-16 skew-x-12 w-14 top-1/4 left-1/2"
      />
      <img
        src="/images/pennyworld/island2-removebg.png"
        alt=""
        class="absolute z-20 w-16 transform translate-x-12 -translate-y-16 skew-x-12 top-1/4 left-1/4"
      />
      <img
        src="/images/pennyworld/island4-removebg.png"
        alt="bottom right island"
        class="absolute z-20 w-32 transform translate-x-40 -translate-y-1/2 bottom-1/4 right-1/4"
      />
      <img
        src="/images/pennyworld/island1-removebg.png"
        alt=""
        class="absolute z-20 transform translate-x-40 -translate-y-1/2 w-28 bottom-1/2 right-1/4"
      />
      <img
        src="/images/pennyworld/island5-removebg.png"
        alt=""
        class="absolute z-20 w-12 transform translate-x-40 top-1/4 right-1/4"
      />
      <img
        src="/images/pennyworld/island5-removebg.png"
        alt=""
        class="absolute z-20 w-12 transform translate-x-24 -translate-y-10 top-1/4 right-1/3"
      />
      <img
        src="/images/pennyworld/island6-removebg.png"
        alt="bottom left island"
        class="absolute z-20 w-40 transform -translate-x-40 bottom-1/4 left-1/4 -translate-y-1/4"
      />
      <img
        src="/images/pennyworld/island4-removebg.png"
        alt="middle left island"
        class="absolute z-20 w-24 transform -translate-x-32 -translate-y-1/2 bottom-1/2 left-1/4"
      />
      <img
        src="/images/pennyworld/island4-removebg.png"
        alt="upper middle left island"
        class="absolute z-20 w-20 transform -translate-x-56 -translate-y-1/2 top-1/3 left-1/4"
      />
      <img
        src="/images/pennyworld/island5-removebg.png"
        alt="upper left island"
        class="absolute z-20 w-16 transform -translate-y-1/2 top-1/4 left-1/4 -translate-x-44"
      />
      <img
        src="/images/pennyworld/island2-removebg.png"
        alt=" left island"
        class="absolute w-16 transform -translate-x-20 top-1/4 left-1/4 -translate-y-1/4"
      />
      <img
        src="/images/pennyworld/island6-removebg.png"
        alt=""
        class="absolute w-10 transform -translate-x-20 -translate-y-16 top-1/4 left-1/4"
      />
    </div>
  </div>
</template>

<style scoped>
.blureffect {
  animation: blur-in 3.5s;
}

@keyframes blur-in {
  0% {
    backdrop-filter: blur(0px);
  }
  50% {
    backdrop-filter: blur(10px);
    filter: blur(15px);
  }
  100% {
    filter: blur(0px);
  }
}
</style>
