<script setup lang="ts">
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { addNewSuccessToast } = useToast();
const TaskListDetails = ref([]);
const isShowConfirmPopup = ref(false);
const clickedTgId = ref('');
const currentStatus = ref('');
const getTaskListDetails = async () => {
  const url = new URL(`${authStore.apiUrl}/api/penny-whale/admin/in-progress-platform-user-tasks`);
  const response = await fetch(url.toString(), {
    headers: {
      'X-API-KEY': authStore.redotKey,
    },
  });
  const data = await response.json();
  TaskListDetails.value = data;
  // console.log(energyUpgradeDetails.value);
  return data;
};

const sendTaskStatus = async (input: string, status: boolean) => {
  const response = await $fetch(
    `${authStore.apiUrl}/api/penny-whale/admin/approve-in-progress-platform-user-tasks`,
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-API-KEY': authStore.redotKey,
      },
      body: {
        tgUserId: input,
        approve: status,
      },
      ignoreResponseError: true,
    },
  );

  if (response.statusCode === 200) {
    getTaskListDetails();
    close();
  } else {
    console.log(response);
  }
};

const close = () => {
  clickedTgId.value = '';
  isShowConfirmPopup.value = false;
  currentStatus.value = '';
};

const handleApprove = (input: string, status: string) => {
  clickedTgId.value = input;
  isShowConfirmPopup.value = true;
  currentStatus.value = status;
};

onMounted(() => {
  getTaskListDetails();
});

const currentImages = ref('');
const visibleRef = ref(false);
const openImage = (input: string) => {
  visibleRef.value = true;
  currentImages.value = input;
};
const onHide = () => ((visibleRef.value = false), (currentImages.value = ''));
</script>

<template>
  <div class="bg-white h-screen w-full px-4 py-4">
    <div class="text-center mx-auto text-2xl font-bold pb-4">
      <div>
        <p>List of task to approve</p>
      </div>
    </div>
    <div>
      <table class="table w-full border-collapse border border-gray-900 text-center mx-auto">
        <thead class="bg-gray-100">
          <tr>
            <th>#</th>
            <th>ID</th>
            <th>TG User ID</th>
            <th>Status</th>
            <th>Image proof</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody class="bg-gray-100">
          <tr v-for="(item, index) in TaskListDetails" class="bg-gray-300 border border-gray-900">
            <td class="border border-gray-900">{{ index + 1 }}</td>
            <td class="border border-gray-900">{{ item?.id }}</td>
            <td class="border border-gray-900 font-semibold">{{ item?.tgUserId }}</td>
            <td class="border border-gray-900 font-semibold capitalize">{{ item?.status }}</td>
            <td class="border border-gray-900 h-auto w-56">
              <img :src="item?.imageUrl" alt="" @click="openImage(item?.imageUrl)" />
              <VueEasyLightbox
                :visible="visibleRef"
                :imgs="currentImages"
                :index="0"
                @hide="onHide"
              />
            </td>
            <td class="border border-gray-900">
              <button
                class="bg-green-500 hover:bg-green-700 text-white font-semibold py-3 px-6 rounded text-lg m-2"
                @click="handleApprove(item?.tgUserId, 'approve')"
              >
                Approve
              </button>
              <button
                class="bg-red-500 hover:bg-red-700 text-white font-semibold py-3 px-6 rounded text-lg"
                @click="handleApprove(item?.tgUserId, 'reject')"
              >
                Reject
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div v-if="isShowConfirmPopup">
      <div>
        <div class="fixed z-10 inset-0 overflow-y-auto">
          <div
            class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
          >
            <div class="fixed inset-0 transition-opacity" aria-hidden="true">
              <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true"
              >&#8203;</span
            >

            <div
              class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div class="sm:flex sm:items-start">
                  <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3 class="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                      Are you sure you want to approve this task?
                    </h3>
                  </div>
                </div>
              </div>
              <div
                class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse gap-4 items-center justify-center"
              >
                <button
                  @click="close()"
                  class="bg-yellow-300 hover:bg-yellow-400 text-black font-semibold py-3 px-6 rounded text-lg"
                >
                  Cancel
                </button>
                <button
                  v-if="currentStatus == 'approve'"
                  @click="sendTaskStatus(clickedTgId, true)"
                  class="bg-green-500 hover:bg-green-700 text-white font-semibold py-3 px-6 rounded text-lg"
                >
                  Approve
                </button>
                <button
                  v-if="currentStatus == 'reject'"
                  @click="sendTaskStatus(clickedTgId, false)"
                  class="bg-red-500 hover:bg-red-700 text-white font-semibold py-3 px-6 rounded text-lg"
                >
                  Reject
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
