<script setup lang="ts">
import { useRouter } from 'vue-router';
const router = useRouter();
const authStore = useAuthStore();
const { authState } = storeToRefs(authStore);
const { customRouterState, navigateToPage } = useCustomRouter();
const purchaseList = ref({
  docs: [],
});
const page = ref(1);
const limit = ref(20);
const totalPages = ref(0);
const mockData = [
  {
    id: 1,
    purchaseId: 59894123,
    date: '2023-02-20, 14:30',
    hashPurchase: 1000,
    bonusCalculated: 'False',
  },
  {
    id: 2,
    purchaseId: 123678901,
    date: '2023-02-19, 10:45',
    hashPurchase: 1000,
    bonusCalculated: 'True',
  },
  {
    id: 3,
    purchaseId: 987652109,
    date: '2023-02-18, 16:15',
    hashPurchase: 1000,
    bonusCalculated: 'False',
  },
  {
    id: 4,
    purchaseId: 123423411,
    date: '2023-02-17, 12:00',
    hashPurchase: 1000,
    bonusCalculated: 'True',
  },
  {
    id: 5,
    purchaseId: 234564572,
    date: '2023-02-16, 15:30',
    hashPurchase: 1000,
    bonusCalculated: 'False',
  },
  {
    id: 6,
    purchaseId: 3564573,
    date: '2023-02-15, 11:15',
    hashPurchase: 1000,
    bonusCalculated: 'True',
  },
  {
    id: 7,
    purchaseId: 43464774,
    date: '2023-02-14, 17:00',
    hashPurchase: 1000,
    bonusCalculated: 'False',
  },
  {
    id: 8,
    purchaseId: 55647435,
    date: '2023-02-13, 13:45',
    hashPurchase: 1000,
    bonusCalculated: 'True',
  },
  {
    id: 9,
    purchaseId: 689546566,
    date: '2023-02-12, 16:30',
    hashPurchase: 1000,
    bonusCalculated: 'False',
  },
  {
    id: 10,
    purchaseId: 734563467,
    date: '2023-02-11, 12:15',
    hashPurchase: 1000,
    bonusCalculated: 'True',
  },
];
const navigateTo = async (page: string) => {
  if (page === 'bonusentitlement') {
    await router.push('/pennywhalestma/admin/salesreport/bonusentitle');
    window.location.reload();
  } else if (page === 'salesreport') {
    await router.replace('/pennywhalestma/admin/salesreport/reportview');
    window.location.reload();
  } else if (page === 'bonusdistribution') {
    await router.replace('/pennywhalestma/admin/salesreport/bonusdist');
    window.location.reload();
  } else if (page === 'passiveminingreport') {
    await router.replace('/pennywhalestma/admin/salesreport/passive-mining');
    window.location.reload();
  }
};

onMounted(async () => {});
</script>

<template>
  <div class="bg-white h-screen w-full px-4 py-4 text-base">
    <!-- Header section -->
    <div class="text-center mx-auto font-bold pb-4">
      <div class="text-center mx-auto text-2xl font-bold pb-4">
        <p>Hash Mining Report</p>
      </div>
      <div class="flex justify-center space-x-2 pb-10">
        <button
          class="bg-white text-black py-2 px-4 rounded border border-gray-300"
          @click="navigateTo('salesreport')"
        >
          Purchase List
        </button>

        <button
          class="bg-white text-black py-2 px-4 rounded border border-gray-300"
          @click="navigateTo('bonusentitlement')"
        >
          Bonus Entitlement
        </button>

        <button
          class="bg-white text-black py-2 px-4 rounded border border-gray-300"
          @click="navigateTo('passiveminingreport')"
        >
          Passive Mining
        </button>

        <button
          class="text-black py-2 px-4 rounded border border-gray-300"
          :class="[customRouterState === 'bonusdistribution' ? ' bg-neutral-300' : 'bg-white']"
          @click="navigateTo('bonusdistribution')"
        >
          Bonus Distribution
        </button>
      </div>
    </div>
    <!-- Bonus Entitlement Section -->
    <p class="text-lg font-bold pb-2">Bonus Distribution</p>
    <div class="overflow-x-auto">
      <table
        class="table w-full border-collapse border border-gray-300 text-center text-sm mx-auto"
      >
        <thead class="bg-gray-100">
          <tr>
            <th>No</th>
            <th>Receiver Tg ID</th>
            <th>Node Mining</th>
            <th>Passive Mining</th>
            <th>Bonus 1</th>
            <th>Bonus 2</th>
            <th>Bonus 3</th>
            <th>Date</th>
            <th>Quota Bal</th>
          </tr>
        </thead>
        <tbody class="bg-white">
          <tr v-for="item in mockData" class="bg-white border border-gray-300">
            <td class="border-b border-gray-500 px-2">{{ item.id }}</td>
            <td class="border-b border-gray-500 px-2">{{ item.purchaseId }}</td>
            <td class="border-b border-gray-500">111 PENNY</td>
            <td class="border-b border-gray-500">211 PENNY</td>
            <td class="border-b border-gray-500">50</td>
            <td class="border-b border-gray-500">10</td>
            <td class="border-b border-gray-500">10</td>
            <td class="border-b border-gray-500">Date</td>
            <td class="border-b border-gray-500">quota(node,passive,bonus 3)</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
